import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { message, Modal } from 'antd'

export default function Index() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState('baoliu')
  const [isPause, setIsPause] = useState(false)
  const [pausePrompt, setPausePrompt] = useState('')

  useEffect(() => {
    document.title = '入学资格保留系统'

    axios.post('/c/retention_admission/school/get', { 
      merchant_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 1) {
          message.warning('当前链接所属学校不存在!')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })

    axios.post('/c/retention_admission/config/get', { 
      merchant_oid: oid
    })
      .then(function (response) {
        if (response.data.is_pause === 1) {
          setPausePrompt(response.data.pause_prompt)
          setIsPause(true)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid])

  const handleApplication = () => {
    if (selectedItem === 'baoliu') {
      navigate('/deferral/application/first/' + oid)
    } else if (selectedItem === 'fuxue') {
      navigate('/readmission/application/first/' + oid)
    }
  }

  const handleQuery = () => {
    if (selectedItem === 'baoliu') {
      navigate('/deferral/query/login/' + oid)
    } else if (selectedItem === 'fuxue') {
      navigate('/readmission/query/login/' + oid)
    }
  }

  return (
    <div className='index-page'>
      <div className='index-bg'></div>
      <div className='index-title'>入学资格保留系统</div>

      <div className='index-items'>
        <div className={`${selectedItem === 'baoliu' ? 'index-item index-selected' : 'index-item'}`} onClick={() => setSelectedItem('baoliu')}>
          { selectedItem === 'baoliu' ? 
            <>
              <div className='index-item-selector1'>
                <img src="/images/check1.png" alt="" />
              </div>
              <div className='index-item-figure'>
                <img src="/images/baoliushenqing-selected.png" alt="" />
              </div>
            </> :
            <> 
             <div className='index-item-selector2'>
                <img src="/images/check2.png" alt="" />
              </div>
              <div className='index-item-figure'>
                <img src="/images/baoliushenqing.png" alt="" />
              </div>
            </>
          }
          <div className='index-item-title'>新生保留入学资格申请</div>
        </div>

        <div className={`${selectedItem === 'fuxue' ? 'index-item index-selected' : 'index-item'}`} onClick={() => setSelectedItem('fuxue')}>
          { selectedItem === 'fuxue' ?
            <>
              <div className='index-item-selector1'>
                <img src="/images/check1.png" alt="" />
              </div>
              <div className='index-item-figure'>
                <img src="/images/fuxueshenqing-selected.png" alt="" />
              </div>
            </> :
            <>
              <div className='index-item-selector2'>
                <img src="/images/check2.png" alt="" />
              </div>
              <div className='index-item-figure'>
                <img src="/images/fuxueshenqing.png" alt="" />
              </div>
            </>
          }
          <div className='index-item-title'>保留入学资格学生复学申请</div>
        </div>
      </div>

      <div className='index-item-button'>
        <div className='index-item-button1' onClick={handleApplication}>
        { selectedItem === 'baoliu' ? '保留申请' : '复学申请' }
        </div>
        <div className='index-item-button2' onClick={handleQuery}>结果查询</div>
      </div>

      <Modal
        width={360}
        open={isPause}
        closable={false}
        footer={null}
        centered
      >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <p>{pausePrompt}</p>
        </div>
      </Modal>
    </div>
  )
}
