import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'
import SelectCollege from './selectCollege'
import SelectMajor from './selectMajor'
import SelectAdmissionDate from './selectAdmissionDate'

export default function DeferralApplicationFirst() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [formValue, setFormValue] = useState(location.state || {})

  useEffect(() => {
    document.title = '保留申请'
  }, [])
  console.log(formValue);
  const nextStep = () => {
    if (!formValue.name) {
      message.warning('请填写学生姓名！')
      return false
    }
    if (!formValue.idNumber) {
      message.warning('请填写学生身份证号！')
      return false
    }
    if (formValue.idNumber.length !== 18) {
      message.warning('学生身份证号的长度错误，应为18位！')
      return false
    }
    if (!formValue.phone) {
      message.warning('请填写联系电话！')
      return false
    }
    if (formValue.phone.length !== 11) {
      message.warning('联系电话的长度错误，应为11位！')
      return false
    }
    if (!formValue.college) {
      message.warning('请选择录取学院！')
      return false
    }
    if (!formValue.major) {
      message.warning('请选择录取专业！')
      return false
    }
    if (formValue.major === '其他' && !formValue.otherMajor) {
      message.warning('请填写录取专业！')
      return false
    }
    if (!formValue.admission_date) {
      message.warning('请选择录取时间！')
      return false
    }
    if (!formValue.reason) {
      message.warning('请选择申请原因！')
      return false
    }
    if (formValue.reason === 4 && !formValue.reasonDescription) {
      message.warning('请填写其他原因说明')
      return false
    }

    axios.post('/c/retention_admission/check_repeat', { 
      merchant_oid: oid, 
      id_number: formValue.idNumber,
      application_type: 1,
      phone: formValue.phone,
      name: formValue.name,
      college: formValue.college,
      major: formValue.major === '其他' ? formValue.otherMajor : formValue.major,
      admission_date: formValue.admission_date
    })
      .then(function (response) {
        if (response.errcode === 0) {
          navigate('/deferral/application/second/' + oid, { state: formValue })
        } else {
          message.warning(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <div className='application-page'>
      <div className='application-progress'>
        <div className='application-progress-currentNumber'>1</div>
        <div className='application-progress-name'>个人信息</div>
        <div className='application-progress-line'></div>
        <div className='application-progress-number'>2</div>
        <div className='application-progress-name'>上传资料</div>
      </div>

      <form className='application-items'>
        <div className='application-item'>
          <label className='application-label' htmlFor="name">姓名</label>
          <input className='application-input'
            type='text'
            id='name'
            placeholder='请填写学生姓名'
            value={formValue.name}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, name: e.target.value }))}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="idNumber">身份证号</label>
          <input className='application-input'
            type='text'
            id='idNumber'
            placeholder='请填写学生身份证号'
            value={formValue.idNumber}
            maxLength={18}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, idNumber: e.target.value }))}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="phone">联系电话</label>
          <input className='application-input'
            type='text'
            id='phone'
            placeholder='请填写联系人手机号'
            value={formValue.phone}
            maxLength={11}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, phone: e.target.value }))}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="college">学院</label>
          <SelectCollege
            id='college'
            major={formValue.major}
            value={formValue.college}
            onChange={setFormValue}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="major">专业</label>
          <SelectMajor
            id='major'
            college={formValue.college}
            value={formValue.major}
            onChange={setFormValue}
          />
          { formValue.major === '其他' &&
            <input className='application-input'
              type='text'
              id='otherMajor'
              placeholder='输入专业名称'
              value={formValue.otherMajor}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, otherMajor: e.target.value }))}
            />
          }
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="college">录取时间【参照录取通知书落款时间】</label>
          <SelectAdmissionDate
            id='admission_date'
            value={formValue.admission_date}
            onChange={setFormValue}
          />
        </div>

        <div className='application-item'>
          <label className='application-label'>保留入学资格原因</label>
          <label className='application-radio'>
            <input
              type='radio'
              value='1'
              checked={formValue.reason === '1'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            应征入伍
          </label>
          <label className='application-radio'>
            <input
              type='radio'
              value='2'
              checked={formValue.reason === '2'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            出国留学
          </label>
          <label className='application-radio'>
            <input
              type='radio'
              value='3'
              checked={formValue.reason === '3'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            身体疾病
          </label>
          <label className='application-radio'>
            <input
              type='radio'
              value='4'
              checked={formValue.reason === '4'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            其他原因
          </label>
          { formValue.reason === '4' &&
            <input className='application-input'
              type='text'
              id='reasonDescription'
              placeholder='其他原因说明'
              value={formValue.reasonDescription}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reasonDescription: e.target.value }))}
            />
          }
        </div>

        <div className='application-button'>
          <div className='application-nextStep' onClick={nextStep}>下一步</div>
        </div>
      </form>
    </div>
  )
}
