import React from 'react'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

const SelectAdmissionDate = ({ 
  id,
  value = null,
  onChange
}) => {

  return (
    <DatePicker
      locale={locale} 
      value={value ? dayjs(value, 'YYYY年MM月') : null}
      onChange={(date, dateString) => onChange(prevValues => ({ ...prevValues, [id]: dateString }))}
      picker="month" 
      style={{height: '50px', marginTop: '10px'}}
    />
  )
}

export default SelectAdmissionDate
