import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

export default function DeferralApplicationSuccess() {
  const { oid } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = '保留申请'
  }, [])

  const handleReturn = () => {
    navigate('/' + oid)
  }

  return (
    <div className='result-page'>
      <div className='result-icon'>
        <img src="/images/chenggong.png" alt="" />
      </div>
      <div className='result-title'>提交成功</div>
      <div className='result-description'>
        {/* 提交成功，请等待我校审核，审校结果会通过短信发送至申请手机号，您也可以通过上级页面的“结果查询”自主查询审核结果。 */}
        提交成功，请等待我校审核，审校结果您可以通过上级页面的“结果查询”自主查询。
      </div>
      <div className='result-button' onClick={handleReturn}>返回</div>
    </div>
  )
}
