import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

export default function ReadmissionQueryWait() {
  const { oid } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = '查询结果'
  }, [])

  const handleReturn = () => {
    navigate('/readmission/query/login/' + oid)
  }

  return (
    <div className='result-page'>
      <div className='result-icon'>
        <img src="/images/shenhezhong.png" alt="" />
      </div>
      <div className='result-title'>审核中</div>
      <div className='result-description'>
        本次申请还在审核中，请耐心等待。
      </div>
      <div className='result-button' onClick={handleReturn}>返回</div>
    </div>
  )
}
