import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { Upload, message } from 'antd'
import { apiBaseURL } from '../config'

export default function ReadmissionApplicationSecond() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [formValue, setFormValue] = useState(location.state || {})
  const [downloadUrl, setDownloadUrl] = useState('')

  useEffect(() => {
    document.title = '复学申请'

    axios.post('/c/retention_admission/config/get', { 
      merchant_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 0 && response.data.readmission_form) {
          setDownloadUrl(response.data.readmission_form)
        } else {
          message.warning('模版文件不存在!')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid])

  const imageUpload = (field) => ({
    name: 'image',
    action: `${apiBaseURL}/common/image/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setFormValue(prevFormValue => ({ ...prevFormValue, [field]: info.file.response.data.image_url }))
      }
    }
  })

  const handleDownload = () => {
    if (!downloadUrl) {
      message.error('下载失败，未找到申请表模版文件');
      return
    }

    if (/MicroMessenger/i.test(navigator.userAgent)) {
      navigator.clipboard.writeText(downloadUrl).then(() => {
        message.success('下载链接已复制，请在浏览器中打开下载或打印')
      }).catch(err => {
        console.error('复制失败: ', err)
      })
    } else {
      window.open(downloadUrl, '_blank')
    }
  }
  
  const handlePreviousStep = () => {
    navigate('/readmission/application/first/' + oid, { state: formValue })
  }

  const handleSubmit = () => {
    if (!formValue.idCardFrontPhoto) {
      message.warning('请上传身份证照片人像面')
      return false
    }
    if (!formValue.idCardBackPhoto) {
      message.warning('请上传身份证照片国徽面')
      return false
    }
    if (!formValue.admissionNoticePhoto) {
      message.warning('请上传录取通知书')
      return false
    }
    if (!formValue.applicationFormPhoto) {
      message.warning('请上传保留入学资格学生复学审批表')
      return false
    }
    if (!formValue.readmissionProofPhoto) {
      message.warning('请上传新生保留入学资格证明')
      return false
    }

    axios.post('/c/retention_admission/submit', { 
      merchant_oid: oid, 
      application_type: 2, 
      name: formValue.name,
      id_number: formValue.idNumber,
      phone: formValue.phone,
      college: formValue.college,
      major: formValue.major === '其他' ? formValue.otherMajor : formValue.major,
      admission_date: formValue.admission_date,
      application_reason_type: Number(formValue.reason),
      application_reason_description: formValue.reasonDescription,
      id_card_front_photo: formValue.idCardFrontPhoto,
      id_card_back_photo: formValue.idCardBackPhoto,
      admission_notice_photo: formValue.admissionNoticePhoto,
      application_form_photo: formValue.applicationFormPhoto,
      application_reason_proof_photo: formValue.applicationReasonProofPhoto,
      readmission_proof_photo: formValue.readmissionProofPhoto
    })
      .then(function (response) {
        if (response.errcode === 0) {
          navigate('/readmission/application/success/' + oid)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  let fileName
  if (formValue.reason === '1') {
    fileName = '退伍证（非必填）'
  } else if (formValue.reason === '3') {
    fileName = '可复学医学证明（非必填）'
  } else {
    fileName = '申请原因证明文件（非必填）'
  }

  return (
    <div className='application-page'>
      <div className='application-progress'>
        <div className='application-progress-number'>1</div>
        <div className='application-progress-name'>个人信息</div>
        <div className='application-progress-line'></div>
        <div className='application-progress-currentNumber'>2</div>
        <div className='application-progress-name'>上传资料</div>
      </div>

      <form className='application-items'>
        <div className='application-item'>
          <label className='application-label'>保留入学资格学生入学审批表</label>
          <Upload {...imageUpload('applicationFormPhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.applicationFormPhoto 
                ? <div className='application-preview'>
                    <img src={formValue.applicationFormPhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
          <div className='application-download' onClick={handleDownload}>
            <div className='application-download-name'>
              <img src="/images/doc.png" alt="" />
              <span>模板-保留入学资格学生入学审批表.pdf</span>
            </div>
            <div className='application-download-button'>点击下载</div>
          </div>
        </div>

        <div className='application-item'>
          <label className='application-label'>身份证照片</label>
          <div className='application-uploadBox'>
            <Upload {...imageUpload('idCardFrontPhoto')} showUploadList={false} accept="image/*">
              <div className='application-upload' style={{width: '45vw'}}>
                {formValue.idCardFrontPhoto 
                  ? <div className='application-preview'>
                      <img src={formValue.idCardFrontPhoto} alt="" />
                    </div>
                  : <>
                      <img src="/images/tianjia.png" alt="" />
                      <span>点击上传人像面</span>
                    </>
                }
              </div>
            </Upload>
            <Upload {...imageUpload('idCardBackPhoto')} showUploadList={false} accept="image/*">
              <div className='application-upload' style={{width: '45vw'}}>
                {formValue.idCardBackPhoto 
                  ? <div className='application-preview'>
                      <img src={formValue.idCardBackPhoto} alt="" />
                    </div>
                  : <>
                      <img src="/images/tianjia.png" alt="" />
                      <span>点击上传国徽面</span>
                    </>
                }
              </div>
            </Upload>
          </div>
        </div>

        <div className='application-item'>
          <label className='application-label'>录取通知书</label>
          <Upload {...imageUpload('admissionNoticePhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.admissionNoticePhoto 
                ? <div className='application-preview'>
                    <img src={formValue.admissionNoticePhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
        </div>
        
       {(formValue.reason === '1' || formValue.reason === '3') &&
        <div className='application-item'>
          <label className='application-label'>{fileName}</label>
          <Upload {...imageUpload('applicationReasonProofPhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.applicationReasonProofPhoto 
                ? <div className='application-preview'>
                    <img src={formValue.applicationReasonProofPhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
        </div>}

        <div className='application-item'>
          <label className='application-label'>新生保留入学资格证明</label>
          <Upload {...imageUpload('readmissionProofPhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.readmissionProofPhoto 
                ? <div className='application-preview'>
                    <img src={formValue.readmissionProofPhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
        </div>

        <div className='application-button'>
          <div className='application-previousStep' onClick={handlePreviousStep}>上一步</div>
          <div className='application-submit' onClick={handleSubmit}>提交</div>
        </div>
      </form>
    </div>
  )
}
