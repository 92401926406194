import axios from 'axios'
import { message } from 'antd'
import { apiBaseURL } from './config'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.baseURL = apiBaseURL

axios.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
	function (response) {
		if (response.status === 200) {
			return response.data;
		}
	}, 
  function (error) {
    if (error.response.status === 403) {
			message.error('没有操作权限!')
    } else if (error.response.status === 413) {
			message.error('提交的文件太大了，请控制在8M以内!')
		} else if (error.response.status === 500) {
			message.error('很抱歉，服务器内部发生了一些异常，请联系管理员处理此故障!')
    } else {
      message.error('网络请求异常!')
    }
	}
)
