import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Select } from 'antd'

const SelectCollege = ({ 
  id,
  major='',
  value = null,
  onChange
}) => {
  const { oid } = useParams()
  const [collegeList, setCollegeList] = useState([])

  useEffect(() => {
    axios.post('/c/retention_admission/college/get', { 
      merchant_oid: oid,
      major: major
  })
      .then(function (response) {
        if (response.errcode === 0) {
          setCollegeList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid, major])

  let items = [
    ...collegeList.map((item) => (
      {
        value: item.name,
        label: item.name
      }
    ))
  ]

  return (
    <Select
      value={value}
      placeholder="请选择录取学院"
      onChange={(value) => onChange(prevValues => ({ ...prevValues, [id]: value }))}
      options={items}
      allowClear
      onClear={() => onChange(prevValues => ({ ...prevValues, [id]: '' }))}
      style={{height: '50px', marginTop: '10px'}}
    /> 
  )
}

export default SelectCollege
