import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { message, Upload } from 'antd'
import { apiBaseURL } from '../config'
import SelectCollege from './selectCollege'
import SelectMajor from './selectMajor'
import SelectAdmissionDate from './selectAdmissionDate'

export default function DeferralApplicationEdit() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [formValue, setFormValue] = useState({})
  const [downloadUrl, setDownloadUrl] = useState('')

  useEffect(() => {
    document.title = '修改保留申请'

    axios.post('/c/retention_admission/config/get', { 
      merchant_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 0 && response.data.deferral_form) {
          setDownloadUrl(response.data.deferral_form)
        } else {
          message.warning('模版文件不存在!')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })

    axios.post('/c/retention_admission/detail/get', { 
      merchant_oid: oid, 
      application_oid: location.state
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue({
            name: response.data.name,
            idNumber: response.data.id_number,
            phone: response.data.phone,
            college: response.data.college,
            major: response.data.major,
            admission_date: response.data.admission_date,
            reason: String(response.data.application_reason_type),
            reasonDescription: response.data.application_reason_description,
            idCardFrontPhoto: response.data.id_card_front_photo,
            idCardBackPhoto: response.data.id_card_back_photo,
            admissionNoticePhoto: response.data.admission_notice_photo,
            applicationFormPhoto: response.data.application_form_photo,
            applicationReasonProofPhoto: response.data.application_reason_proof_photo
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid, location.state])

  const imageUpload = (field) => ({
    name: 'image',
    action: `${apiBaseURL}/common/image/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setFormValue(prevFormValue => ({ ...prevFormValue, [field]: info.file.response.data.image_url }))
      }
    }
  })

  const handleDownload = () => {
    if (!downloadUrl) {
      message.error('下载失败，未找到申请表模版文件');
      return
    }

    if (/MicroMessenger/i.test(navigator.userAgent)) {
      navigator.clipboard.writeText(downloadUrl).then(() => {
        message.success('下载链接已复制，请在浏览器中打开下载或打印')
      }).catch(err => {
        console.error('复制失败: ', err)
      })
    } else {
      window.open(downloadUrl, '_blank')
    }
  }

  const handleSubmit = () => {
    if (!formValue.name) {
      message.warning('请填写学生姓名！')
      return false
    }
    if (!formValue.idNumber) {
      message.warning('请填写学生身份证号！')
      return false
    }
    if (formValue.idNumber.length !== 18) {
      message.warning('学生身份证号的长度错误，应为18位！')
      return false
    }
    if (!formValue.phone) {
      message.warning('请填写联系电话！')
      return false
    }
    if (formValue.phone.length !== 11) {
      message.warning('联系电话的长度错误，应为11位！')
      return false
    }
    if (!formValue.college) {
      message.warning('请选择录取学院！')
      return false
    }
    if (!formValue.major) {
      message.warning('请选择录取专业！')
      return false
    }
    if (formValue.major === '其他' && !formValue.otherMajor) {
      message.warning('请填写录取专业！')
      return false
    }
    if (!formValue.admission_date) {
      message.warning('请选择录取时间！')
      return false
    }
    if (!formValue.reason) {
      message.warning('请选择申请原因！')
      return false
    }
    if (formValue.reason === 4 && !formValue.reasonDescription) {
      message.warning('请填写其他原因说明！')
      return false
    }
    if (!formValue.idCardFrontPhoto) {
      message.warning('请上传身份证照片人像面！')
      return false
    }
    if (!formValue.idCardBackPhoto) {
      message.warning('请上传身份证照片国徽面！')
      return false
    }
    if (!formValue.admissionNoticePhoto) {
      message.warning('请上传录取通知书！')
      return false
    }
    if (!formValue.applicationFormPhoto) {
      message.warning('请上传新生保留入学资格申请表！')
      return false
    }
    if (!formValue.applicationReasonProofPhoto) {
      message.warning('请上传' + {fileName})
      return false
    }

    axios.post('/c/retention_admission/modify', { 
      merchant_oid: oid, 
      application_oid: location.state,
      name: formValue.name,
      id_number: formValue.idNumber,
      phone: formValue.phone,
      college: formValue.college,
      major: formValue.major === '其他' ? formValue.otherMajor : formValue.major,
      admission_date: formValue.admission_date,
      application_reason_type: Number(formValue.reason),
      application_reason_description: formValue.reasonDescription,
      id_card_front_photo: formValue.idCardFrontPhoto,
      id_card_back_photo: formValue.idCardBackPhoto,
      admission_notice_photo: formValue.admissionNoticePhoto,
      application_form_photo: formValue.applicationFormPhoto,
      application_reason_proof_photo: formValue.applicationReasonProofPhoto
    })
      .then(function (response) {
        if (response.errcode === 0) {
          navigate('/deferral/application/success/' + oid)
        } else {
          message.warning(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  let fileName
  if (formValue.reason === '1') {
    fileName = '入伍通知书'
  } else if (formValue.reason === '2') {
    fileName = '国外院校录取offer'
  } else if (formValue.reason === '3') {
    fileName = '病例及医生证明'
  } else if (formValue.reason === '4') {
    fileName = '其他原因的证明文件'
  } else {
    fileName = '申请原因证明文件'
  }

  return (
    <div className='application-page'>
      <form className='application-items'>
        <div className='application-item'>
          <label className='application-label' htmlFor="name">姓名</label>
          <input className='application-input'
            type='text'
            id='name'
            placeholder='请填写学生姓名'
            value={formValue.name}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, name: e.target.value }))}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="idNumber">身份证号</label>
          <input className='application-input'
            type='text'
            id='idNumber'
            placeholder='请填写学生身份证号'
            value={formValue.idNumber}
            maxLength={18}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, idNumber: e.target.value }))}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="phone">联系电话</label>
          <input className='application-input'
            type='text'
            id='phone'
            placeholder='请填写联系人手机号'
            value={formValue.phone}
            maxLength={11}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, phone: e.target.value }))}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="college">学院</label>
          <SelectCollege
            id='college'
            major={formValue.major}
            value={formValue.college}
            onChange={setFormValue}
          />
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="major">专业</label>
          <SelectMajor
            id='major'
            college={formValue.college}
            value={formValue.major}
            onChange={setFormValue}
          />
          { formValue.major === '其他' &&
            <input className='application-input'
              type='text'
              id='otherMajor'
              placeholder='输入专业名称'
              value={formValue.otherMajor}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, otherMajor: e.target.value }))}
            />
          }
        </div>

        <div className='application-item'>
          <label className='application-label' htmlFor="college">录取时间【参照录取通知书落款时间】</label>
          <SelectAdmissionDate
            id='admission_date'
            value={formValue.admission_date}
            onChange={setFormValue}
          />
        </div>

        <div className='application-item'>
          <label className='application-label'>保留入学资格原因</label>
          <label className='application-radio'>
            <input
              type='radio'
              value='1'
              checked={formValue.reason === '1'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            当兵
          </label>
          <label className='application-radio'>
            <input
              type='radio'
              value='2'
              checked={formValue.reason === '2'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            出国
          </label>
          <label className='application-radio'>
            <input
              type='radio'
              value='3'
              checked={formValue.reason === '3'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            因病
          </label>
          <label className='application-radio'>
            <input
              type='radio'
              value='4'
              checked={formValue.reason === '4'}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reason: e.target.value }))}
            />
            其他原因
          </label>
          { formValue.reason === '4' &&
            <input className='application-input'
              type='text'
              id='reasonDescription'
              placeholder='其他原因说明'
              value={formValue.reasonDescription}
              onChange={(e) => setFormValue(prevValues => ({ ...prevValues, reasonDescription: e.target.value }))}
            />
          }
        </div>

        <div className='application-item'>
          <label className='application-label'>新生保留入学资格申请表</label>
          <Upload {...imageUpload('applicationFormPhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.applicationFormPhoto 
                ? <div className='application-preview'>
                    <img src={formValue.applicationFormPhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
          <div className='application-download' onClick={handleDownload}>
            <div className='application-download-name'>
              <img src="/images/doc.png" alt="" />
              <span>模板-新生保留入学资格申请表.pdf</span>
            </div>
            <div className='application-download-button'>点击下载</div>
          </div>
        </div>

        <div className='application-item'>
          <label className='application-label'>身份证照片</label>
          <div className='application-uploadBox'>
            <Upload {...imageUpload('idCardFrontPhoto')} showUploadList={false} accept="image/*">
              <div className='application-upload' style={{width: '45vw'}}>
                {formValue.idCardFrontPhoto 
                  ? <div className='application-preview'>
                      <img src={formValue.idCardFrontPhoto} alt="" />
                    </div>
                  : <>
                      <img src="/images/tianjia.png" alt="" />
                      <span>点击上传人像面</span>
                    </>
                }
              </div>
            </Upload>
            <Upload {...imageUpload('idCardBackPhoto')} showUploadList={false} accept="image/*">
              <div className='application-upload' style={{width: '45vw'}}>
                {formValue.idCardBackPhoto 
                  ? <div className='application-preview'>
                      <img src={formValue.idCardBackPhoto} alt="" />
                    </div>
                  : <>
                      <img src="/images/tianjia.png" alt="" />
                      <span>点击上传国徽面</span>
                    </>
                }
              </div>
            </Upload>
          </div>
        </div>

        <div className='application-item'>
          <label className='application-label'>录取通知书</label>
          <Upload {...imageUpload('admissionNoticePhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.admissionNoticePhoto 
                ? <div className='application-preview'>
                    <img src={formValue.admissionNoticePhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
        </div>

        <div className='application-item'>
          <label className='application-label'>{fileName}</label>
          <Upload {...imageUpload('applicationReasonProofPhoto')} showUploadList={false} accept="image/*">
            <div className='application-upload'>
              {formValue.applicationReasonProofPhoto 
                ? <div className='application-preview'>
                    <img src={formValue.applicationReasonProofPhoto} alt="" />
                  </div>
                : <>
                    <img src="/images/tianjia.png" alt="" />
                    <span>点击上传</span>
                  </>
              }
            </div>
          </Upload>
        </div>

        <div className='application-button'>
          <div className='application-nextStep' onClick={handleSubmit}>提交修改</div>
        </div>
      </form>
    </div>
  )
}
