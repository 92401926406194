import { 
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom'
import Index from './pages'
import DeferralApplicationFirst from './pages/deferralApplicationFirst'
import DeferralApplicationSecond from './pages/deferralApplicationSecond'
import DeferralApplicationEdit from './pages/deferralApplicationEdit'
import DeferralApplicationSuccess from './pages/deferralApplicationSuccess'
import DeferralQueryLogin from './pages/deferralQueryLogin'
import DeferralQueryWait from './pages/deferralQueryWait'
import DeferralQueryNotPassed from './pages/deferralQueryNotPassed'
import DeferralQueryPass from './pages/deferralQueryPass'
import ReadmissionApplicationFirst from './pages/readmissionApplicationFirst'
import ReadmissionApplicationSecond from './pages/readmissionApplicationSecond'
import ReadmissionApplicationEdit from './pages/readmissionApplicationEdit'
import ReadmissionApplicationSuccess from './pages/readmissionApplicationSuccess'
import ReadmissionQueryLogin from './pages/readmissionQueryLogin'
import ReadmissionQueryWait from './pages/readmissionQueryWait'
import ReadmissionQueryNotPassed from './pages/readmissionQueryNotPassed'
import ReadmissionQueryPass from './pages/readmissionQueryPass'

const router = createBrowserRouter([
  {
    path: '/:oid',
    element: <Index />,
  },
  {
    path: '/deferral/application/first/:oid',
    element: <DeferralApplicationFirst />,
  },
  {
    path: '/deferral/application/second/:oid',
    element: <DeferralApplicationSecond />,
  },
  {
    path: '/deferral/application/edit/:oid',
    element: <DeferralApplicationEdit />,
  },
  {
    path: '/deferral/application/success/:oid',
    element: <DeferralApplicationSuccess />,
  },
  {
    path: '/deferral/query/login/:oid',
    element: <DeferralQueryLogin />,
  },
  {
    path: '/deferral/query/wait/:oid',
    element: <DeferralQueryWait />,
  },
  {
    path: '/deferral/query/pass/:oid',
    element: <DeferralQueryPass />,
  },
  {
    path: '/deferral/query/notpassed/:oid',
    element: <DeferralQueryNotPassed />,
  },
  {
    path: '/readmission/application/first/:oid',
    element: <ReadmissionApplicationFirst />,
  },
  {
    path: '/readmission/application/second/:oid',
    element: <ReadmissionApplicationSecond />,
  },
  {
    path: '/readmission/application/edit/:oid',
    element: <ReadmissionApplicationEdit />,
  },
  {
    path: '/readmission/application/success/:oid',
    element: <ReadmissionApplicationSuccess />,
  },
  {
    path: '/readmission/query/login/:oid',
    element: <ReadmissionQueryLogin />,
  },
  {
    path: '/readmission/query/wait/:oid',
    element: <ReadmissionQueryWait />,
  },
  {
    path: '/readmission/query/pass/:oid',
    element: <ReadmissionQueryPass />,
  },
  {
    path: '/readmission/query/notpassed/:oid',
    element: <ReadmissionQueryNotPassed />,
  }
])

export default function Router() {
  return (
    <RouterProvider router={router} />
  )
}
