import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'

export default function ReadmissionQueryLogin() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const [schoolLogo, setSchoolLogo] = useState('')
  const [formValue, setFormValue] = useState({})

  useEffect(() => {
    document.title = ''

    axios.post('/c/retention_admission/school/get', { 
      merchant_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setSchoolLogo(response.data.logo)
        } else {
          message.warning('当前链接所属学校不存在!')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid])

  const handleQuery = () => {
    if (!formValue.name) {
      message.warning('请填写学生姓名')
      return false
    }
    if (!formValue.idNumber) {
      message.warning('请填写学生身份证号')
      return false
    }

    axios.post('/c/retention_admission/query', { 
      merchant_oid: oid, 
      application_type: 2,
      name: formValue.name,
      id_number: formValue.idNumber
    })
      .then(function (response) {
        if (response.errcode === 0) {
          if (response.data.audit_status === 2) {
            navigate('/readmission/query/pass/' + oid, { state: response.data.success_proof_photo })
          } else if (response.data.audit_status === 3) {
            navigate('/readmission/query/notpassed/' + oid, { state: response.data })
          } else {
            navigate('/readmission/query/wait/' + oid)
          }
        } else if (response.errcode === 1) {
          message.warning(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <div className='query-page'>
      <div className='query-login-logo'>
        <img src={schoolLogo} alt="" />
      </div>
      <div className='query-login-title'>保留入学资格学生<br />复学申请结果查询</div>
      <div className='query-login-forms'>
        <div className='query-login-form'>
          <label htmlFor="name">姓名</label>
          <input 
            type="text" 
            id='name' 
            placeholder='请输入姓名'
            value={formValue.name}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, name: e.target.value }))}
          />
        </div>

        <div className='query-login-form'>
          <label htmlFor="idNumber">身份证号</label>
          <input 
            type="text" 
            id='idNumber' 
            placeholder='请输入身份证号'
            value={formValue.idNumber}
            onChange={(e) => setFormValue(prevValues => ({ ...prevValues, idNumber: e.target.value }))}
          />
        </div>

       <div className='query-login-button' onClick={handleQuery}>查询</div>
      </div>
    </div>
  )
}
