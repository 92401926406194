import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'

export default function ReadmissionQueryPass() {
  const { oid } = useParams()
  const [configValue, setConfigValue] = useState({})

  useEffect(() => {
    document.title = '查询结果'

    axios.post('/c/retention_admission/config/get', { 
      merchant_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setConfigValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid])

  const handleDownload = () => {
    if (configValue.reporting_handbook) {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        navigator.clipboard.writeText(configValue.reporting_handbook).then(() => {
          message.success('下载链接已复制，请在浏览器中打开查看或下载')
        }).catch(err => {
          console.error('复制失败: ', err)
        })
      } else {
        window.open(configValue.reporting_handbook, '_blank')
      }
    } else {
      message.warning('模版文件不存在!')
    }
  }

  return (
    <div className='result-page'>
      <div className='result-icon'>
        <img src="/images/chenggong.png" alt="" />
      </div>
      <div className='result-title'>审核通过</div>
      <div className='result-description' onClick={handleDownload}>
        您的申请已通过审核，点击查看
        <span style={{color: '#0619B4'}}>《新生报道手册》</span>
        认真阅读报道事项并按照要求准时报道;并将下方展示的材料邮寄给我们。
      </div>

      <div className='result-info'>
        <div className='result-info-box'>
          <div className='result-info-label'>联系人</div>
          <div className='result-info-content'>{configValue.contact}</div>
        </div>
        <div className='result-info-box'>
          <div className='result-info-label'>联系电话</div>
          <div className='result-info-content'>{configValue.phone}</div>
        </div>
        <div className='result-info-box'>
          <div className='result-info-label'>邮寄地址</div>
          <div className='result-info-content'>{configValue.address}</div>
        </div>
        <div className='result-info-box'>
          <div className='result-info-label'>邮寄材料</div>
          <pre className='result-info-content'>{configValue.readmission_mail_info}</pre>
        </div>
      </div>
    </div>
  )
}
