import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Select } from 'antd'

const SelectMajor = ({ 
  id,
  college = '',
  value = null,
  onChange
}) => {
  const { oid } = useParams()
  const [majorList, setMajorList] = useState([])

  useEffect(() => {
    axios.post('/c/retention_admission/major/get', { 
      merchant_oid: oid,
      college: college 
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setMajorList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid, college])

  let items = [
    ...majorList.map((item) => (
      {
        value: item.name,
        label: item.name
      }
    )),
    {
      value: '其他',
      label: '其他'
    }
  ]

  return (
    <Select
      value={value}
      placeholder="请选择录取专业"
      onChange={(value) => onChange(prevValues => ({ ...prevValues, [id]: value }))}
      options={items}
      allowClear
      onClear={() => onChange(prevValues => ({ ...prevValues, [id]: '' }))}
      style={{height: '50px', margin: '10px 0'}}
    /> 
  )
}

export default SelectMajor
