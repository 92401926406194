import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

export default function ReadmissionQueryNotPassed() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    document.title = '查询结果'
  }, [])

  const handleEdit = () => {
    navigate('/readmission/application/edit/' + oid, { state: location.state.application_oid })
  }

  const handleReturn = () => {
    navigate('/readmission/query/login/' + oid)
  }

  return (
    <div className='result-page'>
      <div className='result-icon'>
        <img src="/images/shibai.png" alt="" />
      </div>
      <div className='result-title'>审核未通过</div>
      <div className='result-description'>
        您的申请未通过审核，原因是: <span style={{color: '#FF3343'}}>{location.state.failure_reason}</span>
      </div>
      <div className='result-button-primary' onClick={handleEdit}>去修改</div>
      <div className='result-button-secondary' onClick={handleReturn}>返回</div>
    </div>
  )
}
